<template>
  <div
    v-if="$vuetify.breakpoint.mdAndUp && getActiveWorkspace"
    id="appTabs"
    class="app-tabs v-tabs-wrapper">
    <v-tabs
      :value="activeTab"
      background-color="transparent"
      :color="tabsDisabled ? '' : 'green'"
      class="sourcery__tabs"
      slider-size="0">
      <v-tab
        v-for="{ icon, link, name, url } in tabs"
        :key="url"
        :href="url"
        :disabled="tabsDisabled"
        class="fs-18 font-fam-poppins pa-0"
        @click.prevent="redirectTo(url)">
        <i :class="'nav-svg-icon icon-' + icon" />
        {{ name }}

        <!-- for projects with starred views only -->
        <template v-if="link === ROUTE_PROJECTS && staringFilteredViews.length">
          <v-menu
            :min-width="175"
            :max-width="450"
            offset-y
            open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="down-icon ms-n2"
                v-on="on">
                mdi-chevron-down
              </v-icon>
            </template>

            <AppHeaderStarProjects
              @edit-star-view="editViewStaring" />
          </v-menu>
        </template>
      </v-tab>
    </v-tabs>

    <!-- to edit starred filtered projects view -->
    <EditStaringFilteredView
      ref="editStaring" />
  </div>
</template>
<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import {
  ROUTE_COLLECTIONS, ROUTE_COMMUNITY_COLLECTIONS, ROUTE_PROJECTS,
  TYPE_WORKSPACE_FREE,
} from '@/constants';

import AppNavBar from '@/mixins/AppNavBar';
import AppHeaderStarProjects from '@/components/App/AppHeaderStarProjects';
import EditStaringFilteredView from '@/components/Projects/ProjectsTable/ProjectsTableStructure/EditStaringFilteredView';
export default {
  name: 'AppTabs',
  components: {
    AppHeaderStarProjects,
    EditStaringFilteredView,
  },
  mixins: [AppNavBar],
  data: () => ({
    ROUTE_PROJECTS,
  }),
  computed: {
    ...mapState(['activeHeaderRoute']),
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
    ...mapGetters('Workspace', ['getActiveWorkspace', 'getActiveWorkspaceType']),
    ...mapState('Projects', ['staringFilteredViews']),
    activeTab() {
      const activeTabs = this.tabs.filter(tab => {
        return tab.link === this.activeHeaderRoute;
      });
      const { url } = activeTabs?.length ? activeTabs[0] : {
      };
      return url;
    },
    tabs() {
      return [
        {
          name: 'Community',
          link: ROUTE_COMMUNITY_COLLECTIONS,
          icon: 'community',
          url: this.resolvedUrl({
            name: ROUTE_COMMUNITY_COLLECTIONS,
            params: {
              wId: this.getActiveWorkspace.id,
              id: 'community',
            },
          }),
        },
        {
          name: 'Collections',
          link: ROUTE_COLLECTIONS,
          icon: 'collections',
          url: this.resolvedUrl({
            name: ROUTE_COLLECTIONS,
            params: {
              wId: this.getActiveWorkspace.id,
              id: this.getDefaultLibraryId,
            },
          }),
        },
        {
          name: 'Projects',
          link: ROUTE_PROJECTS,
          icon: 'projects',
          url: this.resolvedUrl({
            name: ROUTE_PROJECTS,
            params: {
              wId: this.getActiveWorkspace.id,
            },
          }),
        },
      ];
    },
    tabsDisabled() {
      return this.getActiveWorkspaceType === TYPE_WORKSPACE_FREE;
    },
  },
  methods: {
    editViewStaring(staring) {
      this.$refs.editStaring.openWindow(staring);
    },
    redirectTo(url) {
      const { pathname, search } = window.location;
      if (pathname !== url || search !== '') {
        this.$router.push({
          path: url,
          query: {
          },
        });
      }
    },
    resolvedUrl(val) {
      return this.$router.resolve(val).href;
    },
  },
};
</script>
<style scoped lang="scss">
.app-tabs {
  &.v-tabs-wrapper {
    display: flex;
    margin-bottom: 0;
    position: sticky;
    left: 0;

    .v-tabs.sourcery__tabs{
      .v-tab {
        color: var(--v-black-base);
        gap: 10px;
        text-transform: capitalize;
        word-wrap: break-word;

        &::before, &::after {
          display: none;
        }

        &--active, &:hover {
          color: var(--v-green-base);
          [class*="nav-svg-icon"] {
            background-color: var(--v-green-base);
          }
        }

        .down-icon {
          color: inherit;

          &[aria-expanded=true] {
            transform: rotate(180deg);
          }
        }

      }
    }
  }

  ::v-deep .v-tabs-bar__content {
    gap: 40px;
  }

  .v-menu__content {
    top: 75% !important;
  }
}
</style>
